var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CyDataTableYdApi',{ref:"cyDataTable",attrs:{"fetch-available":{ keyPath: 'apiKeys' },"headers":_vm.$static.headers,"link-builder":_vm.getLinkTarget,"bulk":_vm.hasBulkModeEnabled,"searchable-fields":_vm.$static.searchableFields,"key-field":"canonical"},scopedSlots:_vm._u([{key:"table-cmp-header-actions",fn:function(ref){
var selected = ref.selected;
return [(!_vm._.isEmpty(selected))?_c('CyButton',{attrs:{"theme":"error","icon":"delete"},on:{"click":function($event){return _vm.openDeleteModal(selected)}}},[_vm._v(" "+_vm._s(_vm.$tc('deleteApiKeysBtn', selected.length, { nb: selected.length }))+" ")]):_c('CyButton',{directives:[{name:"has-rights-to",rawName:"v-has-rights-to",value:('CreateAPIKey'),expression:"'CreateAPIKey'"}],attrs:{"icon":"add","icon-prepend":"","to":{ name: 'newApiKey' }}},[_vm._v(" "+_vm._s(_vm.$t('addApiKey'))+" ")]),(_vm.showDeleteModal)?_c('CyModal',{attrs:{"header-title":_vm.$tc('confirmDeleteTitle', _vm.toDelete.length),"action-btn-func":_vm.onDelete,"action-btn-text":_vm.$tc('deleteApiKeysBtn', _vm.toDelete.length, { nb: _vm.toDelete.length }),"cancel-btn-func":function () { return _vm.$toggle.showDeleteModal(false); },"modal-type":"delete"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('forms.cannotBeUndone'))+" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$tc('areYouSure', _vm.toDelete.length, { item: _vm.toDelete[0].name }))}})]),(_vm.toDelete.length > 1)?_c('ul',{staticClass:"items-to-delete"},_vm._l((_vm.toDelete),function(ref){
var canonical = ref.canonical;
var name = ref.name;
return _c('li',{key:canonical},[_c('b',[_vm._v(_vm._s(name))])])}),0):_vm._e()]):_vm._e()]}},{key:"table-cmp-body-row",fn:function(ref){
var item = ref.props.item;
return [_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._.truncate(item.description, { length: 80 }))+" ")]),_c('td',[(_vm.canUpdateOwner(item))?_c('CyFormsAssignOwner',{attrs:{"errors":_vm.errors,"form-content":item,"action-btn-func":_vm.assignNewOwner,"sm":""}}):_c('CyButton',{attrs:{"to":{
            name: 'member',
            params: {
              id: item.owner.id,
              backRouteTo: 'apiKeys',
            },
          },"disabled":!_vm.canGoToMember(item),"readonly":!_vm.canGoToMember(item),"theme":"grey","variant":"tertiary","sm":"","member-link":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":item.owner,"simple":"","sm":""}})],1)],1),_c('td',[_c('CyTag',{attrs:{"variant":"default"}},[_vm._v(" "+_vm._s(item.last_seven)+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.formatTimeSinceLastUsed(item.last_used)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }