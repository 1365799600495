<template>
  <div>
    <CyDataTableYdApi
      ref="cyDataTable"
      :fetch-available="{ keyPath: 'apiKeys' }"
      :headers="$static.headers"
      :link-builder="getLinkTarget"
      :bulk="hasBulkModeEnabled"
      :searchable-fields="$static.searchableFields"
      key-field="canonical">
      <template #table-cmp-header-actions="{ selected }">
        <CyButton
          v-if="!_.isEmpty(selected)"
          theme="error"
          icon="delete"
          @click="openDeleteModal(selected)">
          {{ $tc('deleteApiKeysBtn', selected.length, { nb: selected.length }) }}
        </CyButton>
        <CyButton
          v-else
          v-has-rights-to="'CreateAPIKey'"
          icon="add"
          icon-prepend
          :to="{ name: 'newApiKey' }">
          {{ $t('addApiKey') }}
        </CyButton>

        <!-- Delete modal -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmDeleteTitle', toDelete.length)"
          :action-btn-func="onDelete"
          :action-btn-text="$tc('deleteApiKeysBtn', toDelete.length, { nb: toDelete.length })"
          :cancel-btn-func="() => $toggle.showDeleteModal(false)"
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { item: toDelete[0].name })"/>
          </p>
          <ul
            v-if="toDelete.length > 1"
            class="items-to-delete">
            <li
              v-for="{ canonical, name } of toDelete"
              :key="canonical">
              <b>{{ name }}</b>
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>
          {{ item.name }}
        </td>

        <td>
          {{ _.truncate(item.description, { length: 80 }) }}
        </td>

        <td>
          <CyFormsAssignOwner
            v-if="canUpdateOwner(item)"
            :errors="errors"
            :form-content="item"
            :action-btn-func="assignNewOwner"
            sm/>
          <CyButton
            v-else
            :to="{
              name: 'member',
              params: {
                id: item.owner.id,
                backRouteTo: 'apiKeys',
              },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            theme="grey"
            variant="tertiary"
            sm
            member-link
            @click.stop>
            <CyMember
              :member="item.owner"
              simple
              sm/>
          </CyButton>
        </td>

        <td>
          <CyTag variant="default">
            {{ item.last_seven }}
          </CyTag>
        </td>

        <td>{{ formatTimeSinceLastUsed(item.last_used) }}</td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb, displayName, hasNoOwner } from '@/utils/helpers'

export default {
  name: 'CyPageApiKeys',
  components: {
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.apiKeys'), [
      {
        label: this.$t('routes.securitySection'),
        name: 'securitySection',
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.securitySection'),
      description: {
        text: this.$t('routes.securitySectionDescription'),
      },
    }
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
  }),
  computed: {
    ...mapState('organization', {
      apiKeys: (state) => state.available.apiKeys,
    }),
    ...mapState('organization/apiKey', {
      errors: (state) => state.errors,
    }),
    $static () {
      return {
        headers: [
          {
            text: this.$t('forms.fieldName'),
            value: 'name',
            align: 'left',
          },
          {
            text: this.$t('forms.fieldDescription'),
            value: 'description',
            align: 'left',
          },
          {
            text: this.$t('owner'),
            align: 'left',
            value: 'owner',
            sort: (a, b) => {
              return displayName(a).localeCompare(displayName(b))
            },
          },
          {
            text: this.$t('endingWith'),
            value: 'last_seven',
            align: 'left',
          },
          {
            text: this.$t('lastUsed'),
            value: 'last_used',
            align: 'left',
          },
        ],
        searchableFields: [
          {
            name: 'name',
            label: this.$t('name'),
          },
          {
            name: 'description',
            label: this.$t('forms.fieldDescription'),
          },
          {
            name: 'owner',
            label: this.$t('owner'),
            filterFunction: displayName,
          },
        ],
      }
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('DeleteAPIKey')
      return canDelete && !_.$isEmpty(this.apiKeys)
    },
  },
  methods: {
    ...mapActions('organization', [
      'BULK_DELETE',
    ]),
    ...mapActions('organization/apiKey', [
      'UPDATE_API_KEY',
    ]),
    openDeleteModal (toDelete) {
      this.toDelete = toDelete
      this.showDeleteModal = true
    },
    async onDelete () {
      const { toDelete } = this
      await this.BULK_DELETE({ keyPath: 'apiKeys', toDelete })
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
    },
    getLinkTarget ({ canonical: apiKeyCanonical } = {}) {
      return {
        name: 'apiKey',
        params: { apiKeyCanonical },
      }
    },
    formatTimeSinceLastUsed (lastTimeUsedTimestamp) {
      if (!lastTimeUsedTimestamp) return this.$t('neverUsed')
      return this.$date.$formatTimeAgo(lastTimeUsedTimestamp)
    },
    async assignNewOwner ({ formContent, owner }) {
      const apiKey = {
        ...formContent,
        owner: owner?.username,
      }
      const successMessage = this.$t('alerts.success.apiKey.reassigned', {
        apiKeyName: formContent.name,
        owner: displayName(owner),
      })
      await this.UPDATE_API_KEY({ apiKey, successMessage })
      if (_.isEmpty(this.errors)) this.$refs.cyDataTable.retrieveItems()
    },
    canGoToMember ({ owner }) {
      return !hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('GetOrgMember', owner?.username)
    },
    canUpdateOwner ({ canonical, owner }) {
      return hasNoOwner(owner) && this.$cycloid.permissions.canDisplay('UpdateAPIKey', canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:APIKeys',
        addApiKey: 'Add @:APIKey',
        areYouSure: 'Are you really sure you want to delete <b>{item}</b>? | Are you really sure you want to delete the following @:APIKeys?',
        confirmDeleteTitle: '@:forms.btnDelete @:APIKey | @:forms.btnDelete @:APIKeys',
        deleteApiKeysBtn: '@:forms.btnDelete {nb} @:APIKey | @:forms.btnDelete {nb} @:APIKeys',
        endingWith: 'Ending with',
        lastUsed: 'Last used',
        neverUsed: 'Never used',
      },
      es: {
        title: '@:APIKeys',
        addApiKey: 'Agregar @:APIKey',
        areYouSure: '¿Está realmente seguro de que desea eliminar <b>{item}</b>? | ¿Está realmente seguro de que desea eliminar las siguientes @:APIKeys?',
        confirmDeleteTitle: '@:forms.btnDelete @:APIKey | @:forms.btnDelete @:APIKeys',
        deleteApiKeysBtn: '@:forms.btnDelete {nb} @:APIKey | @:forms.btnDelete {nb} @:APIKeys',
        endingWith: 'Terminando con',
        lastUsed: 'Utilizado por última vez',
        neverUsed: 'Nunca utilizado',
      },
      fr: {
        title: '@:APIKeys',
        addApiKey: 'Ajouter une @:APIKey',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir supprimer <b>{item}</b> ? | Êtes-vous vraiment sûr de vouloir supprimer les @:APIKeys suivantes ?',
        confirmDeleteTitle: '@:forms.btnDelete une @:APIKey | @:forms.btnDelete des @:APIKeys',
        deleteApiKeysBtn: '@:forms.btnDelete {nb} @:APIKey | @:forms.btnDelete {nb} @:APIKeys',
        endingWith: 'Se terminant par',
        lastUsed: 'Dernière utilisation',
        neverUsed: 'Jamais utilisé',
      },
    },
  },
}
</script>
